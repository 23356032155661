//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/pages/dashboard
// Description: Dashboard page.
// Created:     FUB, May 25, 2022
//==============================================================================

import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { FaCog } from "react-icons/fa"

import { Page } from "../components/layout/page"
import { Button, ButtonHelp, ButtonPrimary, ButtonSecondary } from "../components/widgets/button"
import { MarketVane } from "../components/widgets/market-vane"
import { MarketOverview } from "../components/widgets/market-overview"
import { useMemberdata, useMembership } from "../utils/member-data"
import { formatCurrency, formatCagr } from "../utils/format-helpers"
import { Border } from "../components/widgets/container"
import { Popup } from "../components/widgets/popup"
import { Toggle } from "../components/widgets/toggle"
import { TableSimple } from "../components/widgets/table"
import { useAccountGroupList, useAccountGroupIndices, accountGroupAllAccounts, accountGroupUngrouped, useAccountGroupInfo, useAccountInfo, usePortfolioInfo } from "../utils/account-data"
import { IbkrBasketTrader } from "../components/widgets/ibkr-basket-trader"
import { Marquee } from "../components/widgets/marquee"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`DASHBOARD: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`DASHBOARD: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
const Settings = ({ setVisibility }) => {
    const [memberData, setMemberdata] = useMemberdata()
    const settings = memberData?.settings

    const notifications = settings?.notifications === true
    const setNotifications = (isOn) => {
        const newSettings = {
            ...settings,
            notifications: isOn,
        }
        DEBUG_MSG(`newSettings = ${JSON.stringify(newSettings)}`)
        setMemberdata({ settings: newSettings })
    }
    DEBUG_MSG(`notifications = ${JSON.stringify(notifications)}`)

    const close = () => setVisibility(false)

    return (<>
        <Popup title="Dashboard Settings" onCancel={close}>
            <p>
                Send email notifications when accounts need rebalancing: <Toggle isChecked={notifications} setChecked={setNotifications} />
            </p>
            <ButtonPrimary text="close" onClick={close} />
        </Popup>
    </>)
}

//------------------------------------------------------------------------------
const RebalanceButton = ({ to }) => {
    return (<ButtonPrimary text="rebalance" to={to} small={true} />)
}

const ReviewButton = ({ to }) => {
    return (<Button text="review" to={to} small={true} />)
}

const AnalyzeButton = ({ to }) => {
    return (<Button text="analyze" to={to} small={true} />)
}

//------------------------------------------------------------------------------
const EmptyRow = () => (<tr>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
</tr>)

const TrClickable = styled.tr`
    cursor: pointer;
`

const TotalRow = ({ group }) => {
    const ids = useAccountGroupIndices(group)
    const info = useAccountGroupInfo(ids)

    const isSubtotal = group !== accountGroupAllAccounts
    const totalUrl = isSubtotal ?
        `/dashboard/total/?ids=${JSON.stringify(ids)}` :
        `/dashboard/total/`
    const openTotal = () => navigate(totalUrl)

    return (
        <TrClickable
            onClick={openTotal}
            isSubtotal={isSubtotal}
            css={`
                td {
                    border-style: none;
                    border-top: ${props => props.isSubtotal ? "dotted" : "solid"} 1px ${props => props.theme.colors.decorative};
                    border-bottom: ${props => props.isSubtotal ? "none" : "double"} ${props => props.theme.colors.decorative};
                }
            `}
        >
            <td>{isSubtotal ? "Subtotal" : "Total"}</td>
            <td>{formatCurrency(info.value, 0)}</td>
            <td>&nbsp;</td>
            <td><AnalyzeButton /></td>
        </TrClickable>
    )
}

const AccountRow = ({ id, group }) => {
    const accountInfo = useAccountInfo(id)
    const portfolioInfo = usePortfolioInfo(accountInfo?.portfolio)

    //DEBUG_MSG(`id=${id}, info=${JSON.stringify(info)}`)
    const name = accountInfo?.name && (accountInfo.name.endsWith(group)
        ? accountInfo.name.replace(group, "").replace(/\s*@\s*$/, "")
        : accountInfo.name)

    const rebalUrl = `/dashboard/rebalance/?id=${id}`
    const openRebal = () => navigate(rebalUrl)

    return accountInfo ?
        (<TrClickable onClick={openRebal}>
            <td>{name}</td>
            <td>{formatCurrency(accountInfo.value, 0)}</td>
            <td css={`max-width: 30vw;`}>
                <Marquee>
                    1wk:&nbsp;{formatCagr(100 * (Math.pow(1 + portfolioInfo.cagr1wk / 100, 0.0192307692) - 1))}%
                    &nbsp;&bull;&nbsp;
                    1mo:&nbsp;{formatCagr(100 * (Math.pow(1 + portfolioInfo.cagr1mo / 100, 0.0833333333) - 1))}%
                    &nbsp;&bull;&nbsp;
                    3mo:&nbsp;{formatCagr(100 * (Math.pow(1 + portfolioInfo.cagr3mo / 100, 0.25) - 1))}%
                    &nbsp;&bull;&nbsp;
                    1yr:&nbsp;{formatCagr(100 * (Math.pow(1 + portfolioInfo.cagr1yr / 100, 1.00) - 1))}%
                </Marquee>
            </td>
            <td>{accountInfo.needsRebalancing ? <RebalanceButton /> : <ReviewButton />}</td>
        </TrClickable>) :
        null
}

const AccountGroup = ({ group }) => {
    const accounts = useAccountGroupIndices(group)
    //DEBUG_MSG(`group=${group}, accounts=${JSON.stringify(accounts)}`)

    return <>
        {group !== accountGroupUngrouped && (
            <tr
                css={`
                    th {
                        border-bottom: dotted 1px ${props => props.theme.colors.decorative};
                    }
                `}
            >
                <th>{group}</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
            </tr>
        )}
        {accounts.map((idx) => <AccountRow key={idx} id={idx} group={group} />)}
        {group !== accountGroupUngrouped && <TotalRow group={group} />}
        <EmptyRow />
    </>
}

const PortfolioTable = () => {
    const allAccounts = useAccountGroupIndices(accountGroupAllAccounts)
    const accountGroups = useAccountGroupList()

    //const membership = useMembership()
    //const hasInfinite = membership?.features.infinite.hasAccess

    const space = <span>&nbsp;</span>
    const ellipsis = <span>&hellip;</span>
    const box = <Border><center>
        <p>
            You did not add any investments, yet. Get started
            by finding the right portfolio to suit your investment objective.
        </p>
        <ButtonPrimary text="explore portfolios" to="/portfolios/" />
    </center></Border>

    //DEBUG_MSG(`accountGroups=${JSON.stringify(accountGroups)}`)

    return allAccounts?.length < 1 ? (
        <TableSimple
            data={{
                columns: ["Name", "Value", "Return", "Action"],
                "Name": [ellipsis, ellipsis, ellipsis],
                "Value": [space, box, space],
                "Return": [space, space, space],
                "Action": [ellipsis, ellipsis, ellipsis],
            }}
        />
    ) : (
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Value</th>
                    <th>Return</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <EmptyRow />
                {accountGroups?.map((group) => (
                    <AccountGroup group={group} key={group} />
                ))}
                <TotalRow group={accountGroupAllAccounts} />
            </tbody>
        </table>
    )
}

//------------------------------------------------------------------------------

const DashboardPage = ({ location }) => {
    const membership = useMembership()
    const [settingsVisible, setSettingsVisible] = useState(false)
    const openSettings = () => setSettingsVisible(true)

    return (
        <Page
            title="Dashboard"
            description="The dashboard is TuringTrader's central place to manage investments."
            location={location}
        >
            <h1>
                Investor's Dashboard
                {membership?.level?.access >= 1 && (<small><Button text={<FaCog />} onClick={openSettings} /></small>)}
            </h1>

            <h2>Your Investments</h2>
            <PortfolioTable />

            <p>
                <br />
                {membership?.features.felix.hasAccess && (<>
                    <IbkrBasketTrader />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </>)}
                <ButtonHelp to="/help/investment-dashboard/" text="how to manage your investments" />
            </p>
            <br />

            <h2>Market Environment</h2>

            <MarketVane />
            <p>
                Most portfolios depend on the stock market as the major driver of returns.
                Consequently, the current <strong>stock market regime</strong> is the most
                important input to successful portfolio decisions. Our <em>Market Vane</em> signal
                provides high-quality guidance in a timely manner.
            </p>
            <p>
                <br />
                <ButtonSecondary to="/dashboard/market-vane/" text="explore market vane" />
            </p>
            <br />
            <br />

            <MarketOverview />
            <p>
                Stock prices typically react to changes in the stock and bond
                markets, commodity prices, the U.S. dollar exchange rate, and
                the economy. Our <strong>Market indicators</strong> provide
                deeper insight into the current market environment and trends.
            </p>
            <p>
                <br />
                <ButtonSecondary to="/dashboard/indicators/" text="explore indicators" />
            </p>
            <br />
            <br />

            {settingsVisible && <Settings setVisibility={setSettingsVisible} />}
        </Page>
    )
}

export default DashboardPage

//==============================================================================
// end of file
