//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/ibkr-basket-trader
// Description: Download for IBKR Basket Trader
// Created:     FUB, Oct 09, 2022
//==============================================================================

import React, { useState, useEffect } from "react"
import { FaFileDownload } from "react-icons/fa"
import { useAccountData } from "../../utils/account-data"
import { formatCurrency } from "../../utils/format-helpers"

/**
 * Download button for IBKR Basket Trader.
 * 
 * Create a button to download the CSV file for IBKR's basket trader.
 * The file has the following format:
 * 
 *     Action,Quantity,Symbol,SecType,Exchange,Currency,TimeInForce,OrderType,AuxPrice,BasketTag,Account,OrderRef
 *     BUY,7,BIL,STK,SMART/AMEX,USD,DAY,MKT,0,"B. Say #1",U1959428,O221009-1524
 *
 * @returns download button
 */
export const IbkrBasketTrader = () => {
    const allAccounts = useAccountData()
    const [theUrl, setUrl] = useState()
    useEffect(() => {
        if (!allAccounts?.accounts)
            return

        let data = "Action,Quantity,Symbol,SecType,Exchange,Currency,TimeInForce,OrderType,AuxPrice,BasketTag,Account,OrderRef"

        for (const idx of allAccounts.accounts) {
            const theAccount = allAccounts[idx]
            const theAccountNo = theAccount.name.match(/[A-Z]\d\d\d\d\d\d+/) ?? theAccount.name
            const theTickers = theAccount.assets
                .filter(t => t !== "---")
                .filter(t => Math.abs(theAccount[t].order) > 1)

            for (const ticker of theTickers) {
                const theAsset = theAccount[ticker]

                data += `\n` +
                    `${theAsset.order > 0 ? "BUY" : "SELL"},` + // Action: BUY
                    `${Math.floor(Math.abs(theAsset.order))},` + // Quantity: 7
                    `${ticker},` + // Symbol: BIL
                    `STK,` + // SecType: STK
                    `SMART/AMEX,` + // Exchange: SMART/AMEX
                    `USD,` + // Currency: USD
                    `DAY,` + // TimeInForce: DAY
                    `MKT,` + // OrderType: MKT
                    `0,` + // AuxPrice: 0
                    `${theAccount.name},` + // BasketTag: Ernie's Account
                    `${theAccountNo},` + // Account: U12345678
                    `"total=${formatCurrency(theAccount.value, 0)}, ` +
                    `alloc=${(100 * theAsset.allocation).toFixed(2)}%, ` +
                    `shares=${theAsset.current.toFixed(0)}=>` +
                    `${theAsset.shares.toFixed(0)}"` // OrderRef: "total=$10,000, alloc=52.65%, shares=286=>58"
            }
        }

        const blob = new Blob([data], { type: "text/csv" })
        const url = URL.createObjectURL(blob)
        setUrl(url)

        return () => URL.revokeObjectURL(url)
    }, [allAccounts])

    return (
        <a href={theUrl} download="basket-trader.csv">
            <FaFileDownload />
            &nbsp;Download for BasketTrader
        </a>
    )
}

//==============================================================================
// end of file
