//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/marquee
// Description: Marquee widget.
// History:     2022 Oct 24, FUB, created
//==============================================================================

// NOTE: this could possibly also achieved with react-fast-marquee
// this implementation was inspired by the CSS found here:
// https://www.w3docs.com/snippets/css/how-to-have-the-marquee-effect-without-using-the-marquee-tag-with-css-javascript-and-jquery.html

import React from "react"
import styled from "styled-components"

const BigScreensOnly = styled.div`
    display: none;
    @media ${props => props.theme.breakpoints.desktop} {
        display: inline-block;
    }
`

const SmallScreensOnly = styled.div`
    display: inline-block;
    @media ${props => props.theme.breakpoints.desktop} {
        display: none;
    }
`

export const Marquee = ({ children }) => (<div css={`overflow: hidden;`}>
    <BigScreensOnly>
        {children}
    </BigScreensOnly>

    <SmallScreensOnly>
        <div css={`
            white-space: nowrap;
            width: fit-content;
            animation: marquee 10s linear infinite;
            @keyframes marquee {
                0% {transform: translateX(100%);}
                100% {transform: translateX(-100%);}
            }
            :hover {
                animation-play-state: paused;
            }
        `}>
            {children}
        </div>
    </SmallScreensOnly>
</div>)

//==============================================================================
// end of file
