//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/market-overview
// Description: Market overview widget.
// History:     2022 May 25, FUB, created
//==============================================================================

import React from "react"
//import styled from "styled-components"

import { Lightbox } from "./lightbox"
import { useMarketOverview } from "../../utils/portfolio-data"

/*const MarketIndicator = ({ title, sm, lg }) => sm ? (<div>
    <Lightbox title={title} src={lg}>
        <div css={`width:100%;border: 1px dotted ${props => props.theme.colors.active}; border-radius: ${props => props.theme.dimensions.cornerRadius};`}>
        <img
            src={sm}
            alt="TuringTrader.com"
            css={`width:100%;`}
        />
        <center><small>{title}</small></center>
        </div>
    </Lightbox>
</div>) : null*/

const MarketIndicator = ({ title, sm, lg }) => sm ? (
    <div
        css={`
            width: 100%;
            border: 1px solid ${props => props.theme.colors.textLight}; 
            border-radius: ${props => props.theme.dimensions.cornerRadius};
            overflow: hidden;
            background-color: ${props => props.theme.colors.bgHighlight};
        `}
    >
        <Lightbox title={title} src={lg}>
            <img
                src={sm}
                alt="TuringTrader.com"
                css={`width:100%;border-bottom: 1px solid ${props => props.theme.colors.decorative}`}
            />
            <div css={`text-align:center;font-size:0.75em;`}>{title}</div>
        </Lightbox>
    </div>
) : null

export const MarketOverview = () => {
    const marketOverview = useMarketOverview()

    return (<>
        <div css={`display:flex;gap:1em;width:100%;margin-bottom:1em;`}>
            <MarketIndicator title="Stock Market" sm={marketOverview?.stockMarketChartSm} lg={marketOverview?.stockMarketChart} />
            <MarketIndicator title="Bond Market" sm={marketOverview?.bondMarketChartSm} lg={marketOverview?.bondMarketChart} />
            <MarketIndicator title="Commodities" sm={marketOverview?.commoditiesChartSm} lg={marketOverview?.commoditiesChart} />
            <MarketIndicator title="US Dollar" sm={marketOverview?.currenciesChartSm} lg={marketOverview?.currenciesChart} />
            <MarketIndicator title="Economy" sm={marketOverview?.economyChartSm} lg={marketOverview?.economyChart} />
        </div>
    </>)
}

//==============================================================================
// end of file
